import { type Registry as Services, inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import { scheduleTask } from 'ember-lifeline';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import ActionBookingsController from 'uplisting-frontend/pods/action/bookings/controller';

export interface IParams {
  booking_id: string;
}

export function scrollRhsToTop(context): void {
  scheduleTask(context, 'render', () => {
    document.getElementById('rhs')?.scrollTo({ top: 0, behavior: 'smooth' });
  });
}

export default class ActionBookingsRoute extends PermittedRoute {
  @service('repositories/booking')
  bookingRepository!: Services['repositories/booking'];

  permission = 'action.bookings';

  model(params: IParams) {
    return this.bookingRepository.findRecord(params.booking_id, {
      reload: true,
    });
  }

  setupController(
    controller: ActionBookingsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { booking: model });

    scrollRhsToTop(this);
  }
}
