import { cached } from '@glimmer/tracking';
import GuestInvoiceModel from 'uplisting-frontend/models/guest-invoice';
import UiBookingPriceTablesIndexComponent from 'uplisting-frontend/pods/components/ui/booking/price/tables';

export default class UiBookingPriceTablesGuestInvoicesComponent extends UiBookingPriceTablesIndexComponent {
  @cached
  get invoicesWithId(): GuestInvoiceModel[] {
    return this.booking.guestInvoices.filter((invoice) => invoice.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Tables::GuestInvoices': typeof UiBookingPriceTablesIndexComponent;
  }
}
