import Model, { attr } from '@ember-data/model';
import {
  AlertStatus,
  type IAlertSchema,
} from 'uplisting-frontend/models/schemas';

export default class AlertModel extends Model implements IAlertSchema {
  @attr('string') title!: string;
  @attr('string') description!: string;
  @attr('string') status!: AlertStatus;
  @attr('date') createdAt!: Date;

  public get isResolved(): boolean {
    return this.status === AlertStatus.resolved;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    alert: AlertModel;
  }
}
