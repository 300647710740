import NotificationsUplistingRoute from 'uplisting-frontend/pods/notifications/uplisting/route';
import type NotificationsUplistingController from 'uplisting-frontend/pods/notifications/uplisting/controller';

export default class NotificationsUplistingResolvedRoute extends NotificationsUplistingRoute {
  async setupController(controller, model, transition): Promise<void> {
    controller = this.controllerFor(
      'notifications.uplisting',
    ) as NotificationsUplistingController;
    super.setupController(controller, model, transition);

    controller.status = 'resolved';
    controller.allAlertsLoaded = false;

    await controller.fetchData('resolved', true);
  }

  resetController(): void {
    const controller = this.controllerFor(
      'notifications.uplisting',
    ) as NotificationsUplistingController;

    controller.alerts = [];
    this.alertsRepository.unloadAll();
  }
}
