import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import sortBy from 'lodash-es/sortBy';
import PermissionModel from 'uplisting-frontend/models/permission';

interface IArgs {
  level?: number;
  isDisabled?: boolean;
  item: PermissionModel;
  onUpdate(item: PermissionModel): void;
}

interface PermissionsRowSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiPermissionsRowComponent extends Component<PermissionsRowSignature> {
  @cached
  get item(): PermissionModel {
    return this.args.item;
  }

  @cached
  get level(): number {
    return this.args.level ?? 0;
  }

  @cached
  get isChildDisabled(): boolean {
    return this.args.isDisabled || !this.item.permitted;
  }

  @cached
  get childrenSorted(): PermissionModel[] {
    return sortBy((this.item.children as PermissionModel[]).slice(), 'title');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Permissions::Row': typeof UiPermissionsRowComponent;
  }
}
