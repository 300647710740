import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import PropertyModel from 'uplisting-frontend/models/property';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import InvitationModel from 'uplisting-frontend/models/invitation';
import TeammateModel from 'uplisting-frontend/models/teammate';
import { searchOptions, toggleHasManyValue } from 'uplisting-frontend/utils';

interface IArgs {
  isCollapsed: boolean;
  record: GenericChangeset<InvitationModel> | TeammateModel;
  showMultiUnits?: boolean;
}

interface PropertiesSelectorSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

type Option = PropertyModel | MultiUnitModel;

function removeIfIncluded(options: Option[], option: Option): void {
  if (options.includes(option)) {
    options.splice(options.indexOf(option), 1);
  }
}

function pushUnlessIncluded(options: Option[], option: Option): void {
  if (!options.includes(option)) {
    options.push(option);
  }
}

function propertiesAndMultiUnitsFor(properties: PropertyModel[]): Option[] {
  return properties
    .map((property) => [property, ...property.multiUnits.slice()])
    .flat();
}

const isProperty = (option: Option): boolean => option instanceof PropertyModel;
const isMultiUnit = (option: Option): boolean =>
  option instanceof MultiUnitModel;

export default class UiPropertiesSelectorComponent extends Component<PropertiesSelectorSignature> {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @cached @tracked inputValue = '';

  @cached
  get record(): GenericChangeset<InvitationModel> | TeammateModel {
    return this.args.record;
  }

  @cached
  get showMultiUnits(): boolean {
    return this.args.showMultiUnits ?? true;
  }

  @cached
  get properties(): PropertyModel[] {
    return this.propertyRepository.peekAll();
  }

  @cached
  get options(): Option[] {
    // For performance there is no need to compute options if that section is collapsed
    if (this.args.isCollapsed) {
      return [];
    }

    if (this.showMultiUnits) {
      return propertiesAndMultiUnitsFor(this.properties);
    }

    return this.properties;
  }

  @cached
  get selectedOptions(): Option[] {
    const { record } = this;

    if (!record) {
      return [];
    }

    return [
      ...record.properties,
      ...record.multiUnits,
      ...propertiesAndMultiUnitsFor(record.properties),
    ];
  }

  @cached
  get filteredOptions(): Option[] {
    return searchOptions<Option>(this.options, this.inputValue, 'searchName');
  }

  @cached
  get isAllSelected(): boolean {
    const { record } = this;

    if (!record) {
      return false;
    }

    const { filteredOptions } = this;

    if (!filteredOptions.length) {
      return false;
    }

    const { properties, multiUnits } = record;

    return filteredOptions.every((option) => {
      if (isProperty(option)) {
        return properties.includes(option as PropertyModel);
      }

      return multiUnits.includes(option as MultiUnitModel);
    });
  }

  @action
  handleInputSearch(value: string): void {
    this.inputValue = value;
  }

  @action
  handelSelectAllClick(): void {
    const {
      filteredOptions,
      record: { properties, multiUnits },
    } = this;

    const filteredProperties = filteredOptions.filter(isProperty);
    const filteredMultiUnits = filteredOptions.filter(isMultiUnit);

    if (this.isAllSelected) {
      filteredProperties.forEach((property) =>
        removeIfIncluded(properties, property),
      );
      filteredMultiUnits.forEach((multiUnit) =>
        removeIfIncluded(multiUnits, multiUnit),
      );
    } else {
      filteredProperties.forEach((option) =>
        pushUnlessIncluded(properties, option),
      );

      if (this.showMultiUnits) {
        filteredMultiUnits.forEach((option) =>
          pushUnlessIncluded(multiUnits, option),
        );
      }
    }
  }

  @action
  handleOptionClick(option: Option): void {
    const { record } = this;
    const { properties, multiUnits } = record;

    if (isProperty(option)) {
      toggleHasManyValue(record, 'properties', option);

      if (!this.showMultiUnits) {
        return;
      }

      const isIncluded = properties.includes(option as PropertyModel);

      (option as PropertyModel).multiUnits.forEach((multiUnit) => {
        if (isIncluded) {
          pushUnlessIncluded(multiUnits, multiUnit);
        } else {
          removeIfIncluded(multiUnits, multiUnit);
        }
      });
    } else {
      toggleHasManyValue(record, 'multiUnits', option);

      const isIncluded = multiUnits.includes(option as MultiUnitModel);

      const { property } = option as MultiUnitModel;

      if (isIncluded) {
        const isAllMultiUnitsSelected = property.multiUnits.every((multiUnit) =>
          record.multiUnits.includes(multiUnit),
        );

        if (!isAllMultiUnitsSelected) {
          return;
        }

        pushUnlessIncluded(properties, property);
      } else {
        removeIfIncluded(properties, property);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Properties::Selector': typeof UiPropertiesSelectorComponent;
  }
}
