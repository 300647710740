export enum AlertStatus {
  pending = 'pending',
  resolved = 'resolved',
}

export interface IAlertSchema {
  title: string;
  description: string;
  status: AlertStatus;
  createdAt: Date;
}
