import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import PromotionRedemptionModel from 'uplisting-frontend/models/promotion-redemption';

interface IArgs {
  promotionId: string;
}

interface DirectPromotionRedemptionsSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiDirectPromotionRedemptionsComponent extends Component<DirectPromotionRedemptionsSignature> {
  @service('repositories/promotion-redemption')
  promotionRedemptionRepository!: Services['repositories/promotion-redemption'];

  @cached @tracked redemptions!: PromotionRedemptionModel[];
  @cached @tracked isFetchingData = false;

  @action
  async handleFetchRedemptionData(): Promise<void> {
    this.isFetchingData = true;

    const data =
      await this.promotionRedemptionRepository.fetchPromotionRedemptionById(
        this.args.promotionId,
      );

    this.redemptions = data;

    this.isFetchingData = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Direct::Promotion::Redemptions': typeof UiDirectPromotionRedemptionsComponent;
  }
}
