import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import {
  UserRoles,
  type InvitationRole,
} from 'uplisting-frontend/models/schemas';
import { type IDropdown } from 'ember-bootstrap/components/bs-dropdown';
import TeammateModel from 'uplisting-frontend/models/teammate';
import { HasManyState } from 'uplisting-frontend/models/helpers';
import PropertyModel from 'uplisting-frontend/models/property';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import PermissionModel from 'uplisting-frontend/models/permission';

interface IArgs {
  teammate: TeammateModel;
  isDisabled?: boolean;
  onRemove(teammate: TeammateModel): void;
  onRoleSelect(role: InvitationRole, teammate: TeammateModel): void;
  onUpdate(teammate: TeammateModel): Promise<void>;
}

interface TeammateRowSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const ALL_ROLES: InvitationRole[] = [UserRoles.housekeeper, UserRoles.host];

export default class UiTeammateRowComponent extends Component<TeammateRowSignature> {
  @service notifications!: Services['notifications'];

  @service('repositories/teammate')
  teammateRepository!: Services['repositories/teammate'];

  @cached @tracked isEditingAccess = false;
  @cached @tracked isEditingPermissions = false;

  @cached @tracked permissions!: PermissionModel[];
  @cached @tracked isFetchingData = false;

  hasManyPropertiesState!: HasManyState<TeammateModel, PropertyModel>;
  hasManyMultiUnitState!: HasManyState<TeammateModel, MultiUnitModel>;

  constructor(owner: unknown, args: IArgs) {
    super(owner, args);

    this.setHasManyState();
  }

  @cached
  get teammate(): TeammateModel {
    return this.args.teammate;
  }

  @cached
  get isDisabled(): boolean {
    return this.args.isDisabled ?? false;
  }

  @cached
  get availableRoles(): InvitationRole[] {
    return ALL_ROLES.filter((role) => this.teammate.role !== role);
  }

  @cached
  get isCollapsed(): boolean {
    return !(
      this.isEditingAccess ||
      this.isEditingPermissions ||
      this.isFetchingData
    );
  }

  @action
  handleRoleSelect(role: InvitationRole, dd: IDropdown): void {
    dd.closeDropdown();

    this.args.onRoleSelect(role, this.teammate);
  }

  @action
  handleExpandEditAccess(): void {
    this.isEditingPermissions = false;
    this.isEditingAccess = true;
  }

  @action
  async handleExpandEditPermissions(): Promise<void> {
    this.isFetchingData = true;

    await this.teammateRepository.fetchTeammateById(this.teammate.id);

    this.isFetchingData = false;

    this.isEditingAccess = false;
    this.isEditingPermissions = true;
  }

  @action
  handleCancel(resetChanges = true): void {
    this.isEditingAccess = false;
    this.isEditingPermissions = false;

    if (resetChanges) {
      this.teammate.setProperties({
        properties: this.hasManyPropertiesState.originalRecords,
        multiUnits: this.hasManyMultiUnitState.originalRecords,
      });
    }
  }

  @action
  async handleUpdate(): Promise<void> {
    await this.args.onUpdate(this.teammate);

    this.handleCancel(false);
    this.setHasManyState();
  }

  private setHasManyState(): void {
    this.hasManyPropertiesState = new HasManyState(this.teammate, 'properties');
    this.hasManyMultiUnitState = new HasManyState(this.teammate, 'multiUnits');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Teammate::Row': typeof UiTeammateRowComponent;
  }
}
