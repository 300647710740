import Component from '@glimmer/component';
import { type Registry as Services, inject as service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { unloadRecord } from 'uplisting-frontend/utils';
import BookingModel from 'uplisting-frontend/models/booking';
import PaymentModel from 'uplisting-frontend/models/payment';
import type BookingRefundActionModel from 'uplisting-frontend/models/booking-refund-action';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';

interface IArgs {
  currency: string;
  index: number;
  booking: BookingModel;
  payment: PaymentModel;
}

interface BookingPriceTablesPaymentsRowSignature {
  Element: HTMLTableRowElement;

  Args: IArgs;
}

export default class UiBookingPriceTablesPaymentsRowComponent extends Component<BookingPriceTablesPaymentsRowSignature> {
  @service intl!: Services['intl'];
  @service notifications!: Services['notifications'];

  @service('repositories/booking-refund-action')
  bookingRefundActionRepository!: Services['repositories/booking-refund-action'];

  @cached @tracked isSaving = false;
  @cached @tracked showRefundModal = false;

  @cached
  get changeset(): GenericChangeset<BookingRefundActionModel> {
    const record = this.bookingRefundActionRepository.createRecord({
      payment: this.payment,
      amount: this.payment.maxRefundAmount,
    });

    return this.bookingRefundActionRepository.buildChangeset(record);
  }

  @cached
  get currency(): string {
    return this.args.currency;
  }

  @cached
  get payment(): PaymentModel {
    return this.args.payment;
  }

  @cached
  get title(): string {
    if (this.payment.guestInvoice) {
      return this.intl.t('action_bookings_price.payments.guest_invoice.title', {
        id: this.payment.guestInvoice.externalId,
      });
    }

    return '';
  }

  @cached
  get sequence(): string {
    return 'I'.repeat(this.args.index + 1);
  }

  @action
  handleShowModal(): void {
    if (!this.payment.isRefundable) {
      return;
    }

    this.showRefundModal = true;
  }

  @action
  handleCloseModal(): void {
    if (!this.isSaving) {
      unloadRecord<BookingRefundActionModel>(this.changeset);
    }

    this.showRefundModal = false;
  }

  @action
  async handleRefund(): Promise<void> {
    this.isSaving = true;

    try {
      await this.changeset.save();

      this.notifications.info(
        'action_bookings_price.refund_modal.success_notification',
      );

      await this.args.booking.reload();
    } catch {
      this.notifications.error();
    } finally {
      this.isSaving = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Tables::Payments::Row': typeof UiBookingPriceTablesPaymentsRowComponent;
  }
}
