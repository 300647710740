import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import { action } from '@ember/object';
import BookingModel from 'uplisting-frontend/models/booking';
import AdditionalBookingChargeModel from 'uplisting-frontend/models/additional-booking-charge';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { unloadRecord } from 'uplisting-frontend/utils';

interface IArgs {
  currency: string;
  booking: BookingModel;
}

export interface BookingPriceAdditionalChargeSignature {
  Element: null;

  Args: IArgs;
}

export default class UiBookingPriceAdditionalChargeComponent extends Component<BookingPriceAdditionalChargeSignature> {
  @service store!: Services['store'];
  @service notifications!: Services['notifications'];

  @service('repositories/tax') taxRepository!: Services['repositories/tax'];

  @service('repositories/additional-booking-charge')
  additionalBookingChargeRepository!: Services['repositories/additional-booking-charge'];

  isSaving = false;

  @cached @tracked showCreateModal = false;

  @cached
  @tracked
  changesets!: GenericChangeset<AdditionalBookingChargeModel>[];

  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }

  @cached
  get charges(): AdditionalBookingChargeModel[] {
    return this.booking.pendingAdditionalCharges;
  }

  @cached
  get isAnyInvalid(): boolean {
    const { changesets } = this;

    if (!changesets) {
      return false;
    }

    return changesets.some((changeset) => changeset.isInvalid);
  }

  @action
  handleShowCreateModal(): void {
    const { charges, additionalBookingChargeRepository } = this;

    if (charges.length) {
      this.changesets = charges.map((charge) => {
        const changeset =
          additionalBookingChargeRepository.buildChangeset(charge);

        if (!changeset?.tax) {
          changeset.tax = this.taxRepository.defaultTaxOption;
        }

        return changeset;
      });
    } else {
      this.changesets = [this.createCharge()];
    }

    this.showCreateModal = true;
  }

  @action
  handleClose(): void {
    if (!this.isSaving) {
      this.changesets.map((changeset) => {
        unloadRecord<AdditionalBookingChargeModel>(changeset);
      });
    }

    this.showCreateModal = false;
  }

  @action
  handleRemoveChargeAt(index: number): void {
    unloadRecord<AdditionalBookingChargeModel>(
      this.changesets[index] as GenericChangeset<AdditionalBookingChargeModel>,
    );

    this.changesets.splice(index, 1);

    this.changesets = [...this.changesets];
  }

  @action
  handleAddCharge(): void {
    this.changesets = [...this.changesets, this.createCharge()];
  }

  @action
  async handleSubmit(): Promise<void> {
    const { changesets } = this;

    await Promise.all(changesets.map((changeset) => changeset.validate()));

    if (this.isAnyInvalid) {
      return;
    }

    changesets.map((changeset) => changeset.execute());

    const bulkCharge = this.store.createRecord(
      'bulk-additional-booking-charge',
      {
        booking: this.booking,
        additionalBookingCharges: changesets.map((item) => item.data),
      },
    );

    try {
      await bulkCharge.save();

      this.notifications.info(
        'action_bookings_price.additional_charge_modal.notifications.saved',
      );

      await this.booking.reload();

      this.handleClose();
    } catch {
      this.notifications.error();
    }
  }

  private createCharge(): GenericChangeset<AdditionalBookingChargeModel> {
    const { additionalBookingChargeRepository } = this;
    const record = additionalBookingChargeRepository.createRecord({
      tax: this.taxRepository.defaultTaxOption,
    });

    return additionalBookingChargeRepository.buildChangeset(record);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::AdditionalCharge': typeof UiBookingPriceAdditionalChargeComponent;
  }
}
