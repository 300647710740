import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, inject as service } from '@ember/service';
import sum from 'lodash-es/sum';
import type TaxModel from 'uplisting-frontend/models/tax';
import type BookingModel from 'uplisting-frontend/models/booking';
import type GuestInvoiceModel from 'uplisting-frontend/models/guest-invoice';
import { unloadRecord } from 'uplisting-frontend/utils';
import type GuestInvoiceActionModel from 'uplisting-frontend/models/guest-invoice-action';
import type AdditionalBookingChargeModel from 'uplisting-frontend/models/additional-booking-charge';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import {
  GuestInvoiceStatus,
  GuestInvoiceAction,
} from 'uplisting-frontend/models/schemas';

interface IArgs {
  currency: string;
  booking: BookingModel;
  invoice?: GuestInvoiceModel;
  isWritable?: true;
  class?: string;
}

export interface BookingPriceGuestInvoiceSignature {
  Element: HTMLTableCellElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

interface ITaxSummary {
  title: string;
  amount: number;
}

export default class UiBookingPriceGuestInvoiceComponent extends Component<BookingPriceGuestInvoiceSignature> {
  @service intl!: Services['intl'];
  @service store!: Services['store'];
  @service notifications!: Services['notifications'];
  @service('repositories/guest-invoice')
  guestInvoiceRepository!: Services['repositories/guest-invoice'];

  @cached @tracked showModal = false;

  @cached @tracked isCharging = false;
  @cached @tracked isSaving = false;
  @cached @tracked changeset?: GuestInvoiceModel;

  @cached
  get currency(): string {
    return this.args.currency;
  }

  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }

  @cached
  get isWritable(): boolean {
    return this.args.isWritable ?? false;
  }

  @cached
  get invoice(): GuestInvoiceModel | GenericChangeset<GuestInvoiceModel> {
    return (
      this.args.invoice ??
      (this.changeset as GenericChangeset<GuestInvoiceModel>)
    );
  }

  @cached
  get modalTitle(): string {
    const { intl } = this;

    if (this.invoice?.id) {
      return intl.t('action_bookings_price.guest_invoice_modal.charge_title', {
        id: this.invoice.externalId,
      });
    }

    return intl.t('action_bookings_price.guest_invoice_modal.settle_title');
  }

  @cached
  get isMissingPayment(): boolean {
    return (
      !this.booking.bookingPayment?.cardValid &&
      this.invoice.status !== GuestInvoiceStatus.markedAsPaid
    );
  }

  @cached
  get taxesSummary(): ITaxSummary[] {
    const charges = this.invoice.additionalBookingCharges.filter(
      (charge) => charge.tax,
    );

    const summaries = {};

    for (const charge of charges) {
      const tax = charge.tax as TaxModel;
      const title = `${tax.type} (${tax.rateReadable})`;

      if (summaries[title]) {
        summaries[title].push(charge);
      } else {
        summaries[title] = [charge];
      }
    }

    return Object.entries<AdditionalBookingChargeModel[]>(summaries).map(
      ([title, items]) => ({
        title,
        amount: sum(items.map((item) => item.taxAmount)),
      }),
    );
  }

  @action
  handleShowModal() {
    this.createInvoiceUnlessPresent();

    this.showModal = true;
  }

  @action
  handleCloseModal(): void {
    this.showModal = false;

    unloadRecord<GuestInvoiceModel>(this.invoice);

    this.changeset = undefined;
  }

  @action
  async handleChargeInvoice(): Promise<void> {
    await this.createInvoiceActionFor(
      GuestInvoiceAction.charge,
      'isCharging',
      (action) => {
        this.notifyPaymentStatus(action.guestInvoice);
      },
    );
  }

  @action
  async handleMarkInvoiceAsVoid(): Promise<void> {
    await this.createInvoiceActionFor(
      GuestInvoiceAction.markVoid,
      'isSaving',
      () => {
        this.notifications.info(
          'action_bookings_price.guest_invoice_modal.marked_as_void',
        );
      },
    );
  }

  @action
  async handleSettleCharge(): Promise<void> {
    this.isSaving = true;

    try {
      await this.invoice.save();

      this.notifications.info(
        'action_bookings_price.guest_invoice_modal.saved',
      );

      this.notifyPaymentStatus(this.invoice);

      await this.booking.reload();
    } catch {
      this.notifications.error();
    } finally {
      this.isSaving = false;
    }
  }

  @action
  handleChangeBilling(markAsPaid: boolean): void {
    this.invoice.status = markAsPaid ? GuestInvoiceStatus.markedAsPaid : null;
  }

  private notifyPaymentStatus(invoice: GuestInvoiceModel): void {
    const keyBase =
      'action_bookings_price.guest_invoice_modal.payment_status_notification.';

    if (invoice.status === GuestInvoiceStatus.paymentError) {
      this.notifications.error(`${keyBase}failed`, {
        error: invoice.payment.lastErrorMessage,
      });
    } else if (invoice.status === GuestInvoiceStatus.paymentMethodMissing) {
      this.notifications.error(`${keyBase}missing`);
    } else if (invoice.status === GuestInvoiceStatus.paid) {
      this.notifications.info(`${keyBase}paid`);
    }
  }

  private async createInvoiceActionFor(
    state: GuestInvoiceAction,
    stateKey: 'isCharging' | 'isSaving',
    callback: (action: GuestInvoiceActionModel) => void,
  ): Promise<void> {
    this[stateKey] = true;

    const action = this.store.createRecord('guest-invoice-action', {
      action: state,
      guestInvoice: this.invoice,
    });

    try {
      await action.save();

      await this.booking.reload();

      callback(action);
    } catch {
      this.notifications.error();
    } finally {
      this[stateKey] = false;
    }
  }

  private createInvoiceUnlessPresent(): void {
    if (this.invoice) {
      return;
    }

    const { booking } = this;

    const record = this.guestInvoiceRepository.createRecord({
      booking,
      additionalBookingCharges: booking.pendingAdditionalCharges,
    });

    this.changeset = this.guestInvoiceRepository.buildChangeset(record);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::GuestInvoice': typeof UiBookingPriceGuestInvoiceComponent;
  }
}
