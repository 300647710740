import { cached } from '@glimmer/tracking';
import UiBookingPriceTablesIndexComponent from 'uplisting-frontend/pods/components/ui/booking/price/tables';
import PaymentModel from 'uplisting-frontend/models/payment';
import BookingPaymentModel from 'uplisting-frontend/models/booking-payment';

export default class UiBookingPriceTablesPaymentsComponent extends UiBookingPriceTablesIndexComponent {
  @cached
  get payment(): BookingPaymentModel {
    return this.booking.bookingPayment as BookingPaymentModel;
  }

  @cached
  get payments(): PaymentModel[] {
    const paidInvoices = this.booking.guestInvoices.filter(
      (invoice) => invoice.isPaid,
    );

    return this.payment.payments
      .concat(paidInvoices.map((invoice) => invoice.payment))
      .filter(Boolean);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Tables::Payments': typeof UiBookingPriceTablesPaymentsComponent;
  }
}
