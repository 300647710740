import { cached } from '@glimmer/tracking';
import UiBookingPriceTablesIndexComponent from 'uplisting-frontend/pods/components/ui/booking/price/tables';
import AdditionalBookingChargeModel from 'uplisting-frontend/models/additional-booking-charge';

export default class UiBookingPriceTablesPendingItemsComponent extends UiBookingPriceTablesIndexComponent {
  @cached
  get chargesWithId(): AdditionalBookingChargeModel[] {
    return this.booking.pendingAdditionalCharges.filter((charge) => charge.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Tables::PendingItems': typeof UiBookingPriceTablesPendingItemsComponent;
  }
}
